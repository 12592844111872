import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    html{
        font-size: 10px;
        display: flex;
        flex-flow: column;
    }

    body{
        margin: 0;
        width: 100%;
    }
`