import React from 'react'
import Helmet from '../components/seo/Helmet'
import styled from 'styled-components'
import GlobalStyles from '../styles/GlobalStyles'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default props => {

  const fluid = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "jacki.jpg"}){
        childImageSharp{
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)?.file?.childImageSharp?.fluid

  return (
    <div>
      <GlobalStyles />
      <Helmet title="ROTTERNE™" />
      <Image fluid={fluid} />
    </div>
  )
}

const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`